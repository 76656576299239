.single-card-container {
    width: 100%;
    background: white;
    justify-content: space-around;
    height: 100px;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3),
        -2px -2px 5px rgb(230 219 219 / 50%);
    border-radius: 10px;

}

.single-card-container .label-item {
    font-weight: 600;
    color: #8c8c8c;
    font-size: 14px;
}

.single-card-container .label-value {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 30px;
}

.chart-container {
    background: white !important;
    border-radius: 12px !important;
    padding: 20px 10px !important;
    width: 100% !important;
    margin-bottom: 30px;
}

.chart-container-header {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.chart-container-heading {
    color: var(--primary-text-color);
    font-size: 18px;
    font-weight: 600;
    padding-left: 20px;
}