.logo {
    height: 120px;
    background: var(--primary-background-color);
    padding-left: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    width: 249px;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
}

.logo img {
    max-height: 60%;

}

.logo .company-name {
    font-style: italic;
}

.side-bar-main {
    border-inline-end: 1px solid#E7E7E7;
    min-width: 250px !important;
    max-width: 250px !important;
    width: 250px !important;
    background: black;
}



.ant-menu-item-selected {
    background-color: var(--primary-text-color) !important;
    color: #CCC !important;
    font-weight: 400;
    font-size: 16px;
}

.ant-menu-item:not(.ant-menu-item-selected) .ant-menu-title-content {
    color: var(--primary-text-color) !important;
    font-weight: 400;
    font-size: 16px;
}

.ant-menu-item-icon {
    max-width: 20px;
    width: 20px;
}

.ant-menu-title-content {
    text-align: left;
}

.top-nav-bar-custom {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    min-width: 100% !important;
}

.top-nav-bar-items-container {
    display: flex;
    gap: 12px;
    margin-right: 20px;
}

.top-nav-bar-item {
    border: 1px solid var(--primary-text-color);
    height: 34px;
    display: flex;
    align-items: center;
    padding: 7px;
    cursor: pointer;
}

.current-active-page-title {
    font-weight: 400;
    font-size: 22px;
    color: var(--primary-text-color);
}

.active-page-header-container {
    display: flex;
    gap: 15px;
    min-width: 200px;
}

.side-bar-menu-items-container {
    background: var(--primary-background-color);
}

.side-bar-menu-items-container .ant-menu-item {
    color: #fff !important;
}

.side-bar-menu-items-container .ant-menu-item-selected {
    color: #ECF0F1 !important;
}

.top-nav-select-control .ant-select-selection-item {
    color: #ECF0F1;
}

.top-nav-sub-item {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 250px);
    padding-left: 20px;
}

.user-profile-options-container {
    border: 1px solid #E2DFFF;
    height: 34px;
    width: 157px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}

.profile-arrow-down {
    max-width: 10px;
    max-height: 10px;
}

.user-profile-container-with-profile-icon {
    display: flex;
    gap: 5px;
}

.user-name-options-label {
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: 400;
}

.meshbill-basic-dashboard-logo {
    font-size: 200%;
    font-weight: 700;
    font-style: italic;
}