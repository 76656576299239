.meshbill-create-record-modal-form-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 50px;
}

.confirmation-message-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.modal-confirmation-message {
    color: var(--primary-text-color);
    font-size: 16px;
    font-weight: 700;
}

.rechargeable-user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
}

.modal-user-name-label {
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-text-color);
}

.modal-user-phone-label {
    font-size: 16px;
    font-weight: 400;
    color: #63686f;
}

.modal-user-address-label {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-card-label-color);
}