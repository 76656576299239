.csv-uploader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 300px;
    background-color: white;
    border-radius: 20px;
    border: 1px solid var(--primary-background-color);
}

.csv-upload-button {
    margin-bottom: 20px;
    font-size: 16px;
    padding: 10px 20px;
}