.filter-item-label {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-text-color);
}

.filter-item-and-control-container {
    display: flex;
    gap: 20px;
    align-items: center;
}