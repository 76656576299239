.staff-profile-card-container {
    margin-top: 20px;
    min-height: 200px;
    border: 1px solid var(--primary-background-color);
    background: white;
    display: flex;
    justify-content: center;
    border-radius: 20px;
}

.profile-card-single-row {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    min-height: 70px;
    align-items: center;
    padding-left: 25px !important;
}

.values-container .user-label-primary {
    color: var(--primary-card-label-color);
    font-size: 14px;
    font-weight: 400;
}

.values-container .user-value {
    color: var(--primary-text-color);
    font-size: 16px;
    font-weight: 700;
}

.staff-details-title-label-container {
    display: flex;
    min-height: 45px;
    height: 45px;
    max-height: 45px;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 5px;
}

.staff-profile-card-title {
    color: var(--primary-text-color);
    font-weight: 500;
    font-size: 18px;
}