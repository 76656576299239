.login-page-background {
    display: flex;
    min-height: 100vh;
    min-width: 100%;
    background: var(--primary-background-color);
    justify-content: center;
}

.login-container {
    padding: 50px;
    padding-top: 0px;
    border-radius: 8px;
    min-width: 30%;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
    z-index: 9999;
    height: 70vh;
    background-color: #FFFFFF;
    margin-top: 10vh;
}

.logo-login-form {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.remember-me-forgot-password-container .forgot-password-label {
    color: #454545;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
}

.meshbill-basic-login-logo {
    font-size: 36px;
    font-weight: 700;
    font-style: italic;
}