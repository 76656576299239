:root {
  --primary-background-color: #D3E0F2;
  --primary-text-color: #2E3138;
  --balance-green: #32CD32;
  --balance-red: #f32f2f;
  --fw-700: 700;
  --primary-card-label-color: #828282;

}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  table-layout: fixed !important;
  width: 100% !important;
  overflow-y: auto !important;
  max-width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}



.ant-table-thead>tr>th {
  color: #C0C0C0 !important;
  background-color: #E8EBFF !important;
  font-weight: 400;
  font-size: 12px;
  /* Replace #yourColorCode with the color you want */
}

.ant-table-tbody tr td {
  color: #515151 !important;
  font-weight: 400;
  font-size: 14px;
  /* Replace #yourColorCode with the color you want */
}

.link-class {
  color: #5E9BE3 !important;
  text-decoration: underline;
}

.border-card {
  border: 1px solid #E7E7E7 !important
}

.general-input-label {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
}

.general-form-input-item {
  height: 45px;
  /* border: 1px solid #979797 */
}

.general-form-title {
  text-align: center;
  color: #515151;
  font-weight: 300;
  font-size: 36px;
  margin-bottom: 30px;
}

.general-form-container {
  max-width: 450px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.span-unblocked {
  display: inline-block;
  color: #C30D1A;
  font-size: 16px;
  font-weight: 400;
}

.span-blocked {
  display: inline-block;
  color: #2F8E38;
  font-size: 16px;
  font-weight: 400;
}

.standard-select-control .ant-menu-item-selected {
  color: var(--primary-text-color) !important;
}

.standard-select-control .ant-select-selection-item {
  color: var(--primary-text-color);
}

.meshbill-modal .ant-modal-content {
  padding: 0 !important;
}

.user-input-general-height {
  min-height: 45px;
}

.create-user-form-label {
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 400;
}

.profile-drop-down-item {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 400;
}

.package-color-container {
  min-height: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}

.blink-label {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.custom-menu-icon {
  font-size: 14px;
}

.non-active-menu-icon {
  color: var(--primary-text-color) !important;
}

.full-width-row {
  width: 100%;
}

.modal-close-icon-customized {
  font-weight: var(--fw-700);
  font-size: 26px;
  color: var(--primary-text-color);
}

.general-label-class {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-text-color);
}

.balance-available-label {
  color: var(--balance-green);
  font-weight: bold;
}

.balance-minus-label {
  color: var(--balance-red);
  font-weight: bold;
}

.border-bottom-1px {
  border-bottom: 1px solid var(--primary-background-color)
}

.disabled-user {
  text-decoration: line-through;
}

.label-for-username-in-a-modal {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-card-label-color);
}

.value-for-username-in-a-modal {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-text-color);
}

.online-user {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--balance-green);
  border-radius: 4px
}

.offline-user {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--balance-red);
  border-radius: 4px
}

.import-table-header {
  color: var(--primary-text-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 15px;
}

.import-table-header-container {
  border-bottom: 1px solid var(--primary-text-color);
  margin-bottom: 10px;
}

.import-custom-titles-container {
  background: #D3E0F2;
  min-height: 38px;
  overflow: hidden;
  margin-left: 1px !important;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.import-custom-titles-label {
  color: var(--primary-text-color);
  font-weight: var(--fw-700);

}

.global-settings-card {
  width: 100%;
  min-height: 300px;
}