.general-table-heading {
    color: var(--primary-text-color);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 15px;

}

.general-table-container {
    background: white;
    border: 1px solid var(--primary-background-color);
}

.general-table-container .ant-table-thead>tr>th {
    color: var(--primary-text-color) !important;
    font-weight: var(--fw-700) !important;
    background-color: var(--primary-background-color) !important;
}

.general-table-container .ant-table-tbody>tr {
    cursor: pointer;
}

.table-cell-action-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.users-search-container {
    display: flex;
    justify-content: space-between;
    height: inherit;
    align-items: center;
    padding-right: 20px
}