.meshbill-tabs-main {
    display: flex;
    justify-content: center;
    width: 100%;
}

.meshbill-tabs-container {
    background-color: var(--primary-background-color);
    height: 45px;
    border-radius: 25px;
    width: 98%;
}

.meshbill-tab-single {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.meshbill-tab-active-single {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background:linear-gradient(276.9deg, #67318C 5.85%, #1C1997 93.97%); */
    background: linear-gradient(90deg, #2E3138 0%, #5A5D63 100%);
    border-radius: 25px;
    cursor: pointer;
}

.meshbill-tab-single .meshbill-tab-text {
    color: var(--primary-text-color);
    font-size: 16px;
    font-weight: 400;
}

.meshbill-tab-active-single .meshbill-tab-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}